<template>
   

    <select  v-model="currency_code" class="theme-form-select" @change="updateCurrency">
        <option v-for="(locale, i) in CurrenciesData" :key="`locale-${i}`" :value="locale" style="color: #645b5b;">
            <img src="assets/images/country/united-kingdom.png"
                        class="img-fluid lazyload" alt="">
                    <span>{{ getCurrencyFullName(locale) }}</span>
            
        </option>
    </select>
</template>
<script>
// import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import router from '../router/index';
import axios from 'axios';
export default {
    name: "currency-selector",
    data() {
        return {
             locales: ["$", "Rs","€"],
             CurrenciesData: [],   
        cookieCurrency:'',
        currency_code:'',
        defaulturrencyCode:null,
       // $i18n.locale: this.LoginUserLanguageCode

     };
      
    },
     computed: {
   

    },
     watch: {
             cookieCurrency(newValue){
            Cookies.set("CookiesCurrencyCode", newValue, { expires: 6 });
            this.$emit('selected-currency-event', newValue);
            router.push('/');
            window.location.reload();
            

        }
    },
    methods: {
        updateCurrency(){
            this.cookieCurrency = this.currency_code;
                // Store the selected language in a cookie with a 6-day expiration
                
           
        },
        
        getCurrencyFullName(locale) {
            // Add logic here to return the full name based on the locale
            switch (locale) {
                case "USD":
                    return "USD (US Dollar)";
                case "EUR":
                    return "EUR (EURO)";
                case "AUD":
                    return "AUD (Australian Dollar)";
                case "NZD":
                    return "NZD (New Zealand Dollar)";
                case "INR":
                    return "INR (Indian Rupee)";
                case "GBP":
                    return "GBP (British Pound)";
                    case "JPY":
                    return "JPY (Japanese Yen)";
                case "LKR":
                    return "LKR (Sri Lankan Rupee)";
                case "SGD":
                    return "SGD (Singapore Dollar)";
                    case "CAD":
                    return "CAD (Canadian Dollar)";
                case "AED":
                    return "AED (AED Dirham)";
                default:
                    return "";
            }
        },
         async fetchCurrenciesdata() {
            try {
                const response = await axios.get('Currency/list'); // Replace with your API endpoint
                this.CurrenciesData = response.data;
                Cookies.set('CookieCurrenciesData', JSON.stringify(this.CurrenciesData), { expires: this.expDay });
              // console.log('currency',this.CurrenciesData);
            } catch (error) {
                console.error('Error fetching currencies data:', error);
            }
        },

        async fetchDefaultCurrenciesdata() {
            try {
                const response = await axios.get('defalutCurrency/list'); // Replace with your API endpoint
                this.defaulturrencyCode = response.data;
                this.currency_code = response.data;
                Cookies.set("CookiesCurrencyCode", response.data, { expires: this.expDay });
                this.$emit('selected-currency-event', response.data);
              //  console.log('currency',this.defaulturrencyCode);
            } catch (error) {
                console.error('Error fetching currencies data:', error);
            }
        },
        
    },
     mounted() {
      // console.log('cookieLanguage',Cookies.get("CookiesLanguageCode"))
       if(Cookies.get("CookiesCurrencyCode")){
            this.currency_code = Cookies.get("CookiesCurrencyCode"); 
       }else{
            this.fetchDefaultCurrenciesdata();
            this.currency_code = this.defaulturrencyCode;
            Cookies.set("CookiesCurrencyCode", this.defaulturrencyCode, { expires: this.expDay });
       }

       if(Cookies.get("CookieCurrenciesData")){
            this.CurrenciesData = JSON.parse(Cookies.get('CookieCurrenciesData'));
            
        }else{
            this.fetchCurrenciesdata();         
        }
      
    }
};
</script>

<style scoped>
.theme-form-select span{
    color:Black;
}
.theme-form-select {
    padding: 0;
    background-color: transparent;
    color: #fff;
    border: none;
    background-image: none;
    position: relative;
}
</style>