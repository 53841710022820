
export default {

    data() {
        return {
         DefaultLanguage:null, 
        };
      },

    computed: {
        baseUrl() {
            // Extract the main domain and prepend 'admin.' subdomain
            // const { hostname } = window.location;
            // const parts = hostname.split('.');
            // let domain = '';
      
            // if (parts.length >= 2) {
            //   domain = parts.slice(-2).join('.'); // Get the last two parts (main domain)
            // }
      
            // return `${window.location.protocol}//admin-stage.${domain}/`;
            // Replace this with your actual base URL
            return 'https://admin-stage-mve-demo2.techquestglobal.com/';           
            //https://admin-stage.emorebuymore.lk/http://127.0.0.1:8000// https://admin-theme-two-stage.emorebuymore.lk
        },
        baseFrontUrl() {
            // Replace this with your actual base URL
           
           // return `${window.location.protocol}//${window.location.hostname}/`;
            return 'https://mve-demo2.techquestglobal.com/';
            //https://stage.emorebuymore.lk/http://localhost:8080 ///https://mve-demo2.techquestglobal.com
        },
        default_userImage(){
            return require('@/assets/images/user_avatar.jpeg');
        },
        default_logoImage(){
            return require('@/assets/images/logo_avatar.jpg');
        },
        paginationItemCount(){
            return 15;
        },
        expDay(){
            return 1;
        },
        captchaSiteKey(){
            return '6LeaOMEpAAAAAPIBIaPa1Ih8VDsFVv77hICi8AD6';
        },
        baseAdminUrl() {
            // Extract the main domain and prepend 'admin.' subdomain
            const { hostname } = window.location;
            const parts = hostname.split('.');
            let domain = '';
      
            if (parts.length >= 2) {
              domain = parts.slice(-2).join('.'); // Get the last two parts (main domain)
            }
      
            return `${window.location.protocol}//admin-stage.${domain}/`;
          },
    },

    methods: {
        
    },
    mounted() {
        // console.log(`${window.location.protocol}//${window.location.hostname}/`);
        // console.log(this.baseAdminUrl);
      },
   
}; 