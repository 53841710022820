<template>
    <div class="col-md-auto">
        <div class="top-left-header">
            <!-- <i class="iconly-Location icli text-white"></i>
            <span class="text-white">{{ addressData }}</span> -->            
            <a :href="baseUrl + sellerpannel" class="btn seller-Link" target="_blank">Become a seller</a>              
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';

export default {

components: {

},
props: {
    
   
},
data() {
    return {
       FrontData:[],
       sellerpannel:'seller/login'
    };
},
computed: {
   addressData(){
    const addressItems = this.FrontData.filter(item => item.field_name === 'address');
    const addressDataArray = addressItems.map(item => item.data);
    return addressDataArray.length > 0 ? addressDataArray[0] : '';
   },
},
methods: {
},
setup() {
   
    

   




    return {
       

    };
},

mounted() {
   
    if (Cookies.get('CookiesFrontenddata')) {
        this.FrontData = JSON.parse(Cookies.get('CookiesFrontenddata'));
    }
},
};
</script>
<style scoped>
.seller-Link{
    text-decoration: none;
    color: #ffff;
    border: 2px solid var(--theme-3-color);
}
</style>