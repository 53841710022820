<template>
  
    <select  v-model="$i18n.locale" class="theme-form-select" @change="updateLanguage">

        <option v-for="(locale, i) in languagesCode" :key="`locale-${i}`" :value="locale" style="color: #645b5b;">
            
                    <!-- <img :src="baseUrl + data.flag"
                        class="img-fluid lazyload" alt="flag"> -->
                    <span>{{ getLanguageFullName(locale) }}</span>
                
            
        </option>
    </select>

</template>

<script>
import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import router from '../router/index';
import axios from 'axios';
export default {
    name: "language-selector",
    data() {
        return {
             locales: ["fr", "en","du"],
             languagesData: [],  
             languagewithflag:[], 
             cookieLanguage:'',
             languagesCode:[]
       // $i18n.locale: this.LoginUserLanguageCode

     };
      
    },
    props: {
        LoginUserLanguageCode: {
           type: String,
            default: "en"
        },
      
    },
     computed: {
       storedLoginUserLanguage() {
            const store = useStore();

            return store.getters.getUserLanguageCode;
        },

    },
     watch: {
        LoginUserLanguageCode(newValue) {
            // Update the selectedLocale when LoginUserLanguageCode changes
            this.$i18n.locale = newValue;
        },
        cookieLanguage(newValue){
            Cookies.set("CookiesLanguageCode", newValue, { expires: 6 });
            this.$emit('selected-language-event', newValue);
            router.push('/');
            window.location.reload();

        }
    },
    methods: {
        updateLanguage(){
            this.cookieLanguage = this.$i18n.locale;
                // Store the selected language in a cookie with a 6-day expiration
                
           
        },
        getLanguageFullName(locale) {
            // Add logic here to return the full name based on the locale
            switch (locale) {
                case "fr":
                    return "Français";
                case "en":
                    return "English";
                case "du":
                    return "Nederlands";
                case "ta":
                    return "தமிழ்";
                case "si":
                    return "සිංහල";
                case "de":
                    return "Deutsch";
                case "es":
                    return "Spanish";
                case "it":
                    return "Italiano";
                case "ja":
                    return "Japanese";
                case "no":
                    return "norsk";
                case "ru":
                    return "Русский";
                case "ae":
                    return "عربي";
                default:
                    return "";
            }
        },
         async fetchLanguagedata() {
           
            try {
                const response = await axios.get('Language/list'); 
                  this.languagesCode = response.data.language_codes;
                  this.languagesData = response.data.language_data;
                  Cookies.set('CookieLanguagecodes', JSON.stringify(this.languagesCode), { expires: this.expDay });
                  Cookies.set('CookieLanguageData', JSON.stringify(this.languagesData), { expires: this.expDay });
            } catch (error) {
                console.error('Error fetching language data:', error);
            }
        },
        async fetchDefaultLanguage() {
            try {
              const response = await axios.get('home/getDefaultLanguage');
              this.$i18n.locale = response.data;
              Cookies.set("CookiesLanguageCode", response.data, { expires: this.expDay });
            // console.log(this.DefaultLanguage)
            } catch (error) {
              console.error('Error fetching Default language data:', error);
            }
          },
        
    },
     mounted() {
      // console.log('cookieLanguage',Cookies.get("CookiesLanguageCode"))
    //   Cookies.remove('CookieLanguagecodes');
    //   Cookies.remove('CookieLanguageData');
       if(Cookies.get("CookiesLanguageCode")){
        this.$i18n.locale = Cookies.get("CookiesLanguageCode"); 
       }else{
            this.fetchDefaultLanguage();          
        }

        if(Cookies.get("CookieLanguagecodes")){
            this.languagesCode = JSON.parse(Cookies.get('CookieLanguagecodes'));
            if(Cookies.get('CookieLanguageData')){
                this.languagesData = JSON.parse(Cookies.get('CookieLanguageData'));
            }           
        }else{
            this.fetchLanguagedata();        
        }
        
    }
};
</script>
<style scoped>
.theme-form-select span{
    color:Black;
}
.theme-form-select {
    padding: 0;
    background-color: transparent;
    color: #fff;
    border: none;
    background-image: none;
    position: relative;
}
</style>