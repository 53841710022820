<template>
    <li class="product-box-contain">
        <div class="drop-cart">
            <a href="product-left-thumbnail.html" class="drop-image">
                <img :src="baseUrl + item.image" :alt="item.name" class="blur-up lazyload" style="width:50%;height: 50%;">
            </a>

            <div class="drop-contain">
                <a href="product-left-thumbnail.html">
                    <h5>{{ item.name }}</h5>
                </a>
                <!-- <h6><span>1 x</span> $80.58</h6> -->
                <!-- <button class="close-button close_button">
                    <i class="fa-solid fa-xmark"></i>
                </button> -->
            </div>
        </div>
    </li>
</template>

<script>
//import { RouterLink } from 'vue-router'
//import { ref, onMounted, computed } from 'vue';

import axios from 'axios';
import Cookies from 'js-cookie';
//import router from '../router/index';
export default {
    name: 'Home-product-section',
    props: {
        item: {
            type: Object,
            required: true
        }
    },

    components: {
        //  RouterLink,

    },

    data() {
        return {
            count: 0 // Initial count value
        };
    },


    methods: {
        getdiscountprice(price, discount) {
            var value = (price - (price * discount) / 100)

            return value;
        },
        getdealDiscountprice_saleprice(sale_price, unit_price, deal_discount) {
            // console.log('sale_price',sale_price,'unit_price',unit_price,'deal_discount',deal_discount)
            var value = (unit_price - (unit_price * deal_discount) / 100);
            if (value < sale_price) {
                return value;
            } else {
                return sale_price;
            }
        },
        getdealDiscountprice_discountprice(discount, unit_price, deal_discount) {
            var deal_value = (unit_price - (unit_price * deal_discount) / 100);
            var no_deal_value = (unit_price - (unit_price * discount) / 100);
            if (no_deal_value < deal_value) {
                return deal_value;
            } else {
                return no_deal_value;
            }
        },
        getdealDiscountprice_nosaleprice(unit_price, deal_discount) {
            var value = (unit_price - (unit_price * deal_discount) / 100);
            return value;
        },

        AddproductIncard(ProductId) {
            // console.log(Cookies.get("LoginStatus"),Cookies.get("CustomerBearedtoken"))
            // console.log('product',ProductId,'count',this.count);

            if (Cookies.get("LoginStatus") == 'true') {
                //  console.log('product',ProductId,'count',this.count, Cookies.get("CustomerBearedtoken"),Cookies.get("CustomerLoginId"));
                this.AddProductTocard(ProductId, this.count);
            } else {

                window.location.href = '/login'; // Navigate to the login route
            }
        },
        async AddProductTocard(Product_id, count) {
            var token = Cookies.get("CustomerBearedtoken");
            var userId = Cookies.get("CustomerLoginId");

            const headers = { Authorization: `Bearer ${token}` };
            var data = {
                productId: Product_id,
                count: count,
                customer_id: userId,
            };


            try {
                const response = await axios.post('Customer/addTocardProduct', data, { headers }); // Replace with your API endpoint
                console.log('data', response.data);
                if (response.data == true) {
                    this.count = 0;
                }
                else {
                    window.location.href = '/login';
                }

            } catch (error) {

                console.error('Error customer addtocard data:', error);
            }
        },
    },
    mounted() {
        // console.log(this.item);
    }

}
</script>
