<script>
import Cookies from 'js-cookie';
import axios from 'axios';

export default {

    components: {

    },
    props: {
        
       
    },
    data() {
        return {
           FrontData:[],
           currentYear: new Date().getFullYear(),
           FrontendImage: [],
           categoryDatas: [],
           LoginCustomerId:null,
           LoginStatus:null,
           SocialmediaData: [],
           SpecialFeature: [],
           language:null,
           phonenoData:null,
           emailData:null,
           SupportcenterEmail:null,
           descriptionData:null,
           addressData:null,
           companyName:null,
           IOSappLink:null,
           AndroidappLink:null,
           Footerwidgetsettings: [],
           FootersettingsData: [],
           PaymentImage: null,
        };
    },
    computed: {

    },
    methods: {
        async fetchFooterData(language) {
                var logo = 'logo';
            try {
                const response = await axios.get(`footerData?area_label=${logo}&language=${language}`);
                this.categoryDatas = response.data.categories_data;
                // this.FrontendImage = response.data.frontend_image;
                this.SocialmediaData = response.data.socialmedia_data;
                this.SpecialFeature = response.data.special_feature_data;
                this.FrontData = response.data.frontend_data;
                this.Footerwidgetsettings = response.data.footer_widget_setting;
                this.FootersettingsData =response.data.footer_settings_data;
                this.PaymentImage = response.data.payment_methods;

                Cookies.set('CookieFootercategoryDatas', JSON.stringify(this.categoryDatas), { expires: this.expDay });
                Cookies.set('CookieFooterSocialmediaData', JSON.stringify(this.SocialmediaData), { expires: this.expDay });
                Cookies.set('CookieFooterSpecialFeature', JSON.stringify(this.SpecialFeature), { expires: this.expDay });
                Cookies.set('CookieFooterFrontData', JSON.stringify(this.FrontData), { expires: this.expDay });
                Cookies.set('CookieFooterFooterwidgetsettings', JSON.stringify(this.Footerwidgetsettings), { expires: this.expDay });
                Cookies.set('CookieFooterFootersettingsData', JSON.stringify(this.FootersettingsData), { expires: this.expDay });
                Cookies.set('CookiePaymentimages', JSON.stringify(this.PaymentImage), { expires: this.expDay });
                
                 // console.log('Cookie set:', Cookies.get('CookieFooterFrontData'));

                  // console.log('response',response.data.footer_settings_data);
                 // console.log(this.PaymentImage);

            } catch (error) {
                console.error('Error fetching Footer Data:', error);
            }
        },
        redirectpageUrl(content, link) {
            console.log(content, link, this.LoginCustomerId,this.LoginStatus);
            var endpoint = `${link}${this.LoginCustomerId}`;
            console.log(endpoint);

            if(this.LoginStatus == 'true'){
                this.$router.push({ path: endpoint, query: { name: 'abc' } })
                .catch(() => {})
                .finally(() => {
                    // Reload the window
                    //  window.location.reload();
                });
            }else{
                this.$router.push({ path: '/login' })
                .catch(() => {})
                .finally(() => {
                    // Reload the window
                    //  window.location.reload();
                });
            }
        },
        redirectpagewithoutLoginUrl(content, link, slug) {

            if(!slug){
                this.$router.push({ path: link })
                .catch(() => {})
                .finally(() => {
                    // Reload the window
                    //  window.location.reload();
                });
            }else{
                
                this.$router.push({ path: link, query: { name: slug } })
                .catch(() => {})
                .finally(() => {
                    // Reload the window
                    //  window.location.reload();
                });
            }
        },
    },
    setup() {
        return {
           

        };
    },

    mounted() {
        if(Cookies.get("CookiesLanguageCode")){
            this.language = Cookies.get("CookiesLanguageCode"); 
        }else{
            this.language = 'en';
        }
       
       this.LoginCustomerId = Cookies.get("CustomerLoginId");
       this.LoginStatus = Cookies.get('LoginStatus');

        if(Cookies.get("CookieFrontendImage")){
            this.FrontendImage = JSON.parse(Cookies.get('CookieFrontendImage'));                    
        }

         // Cookies.remove("CookieFootercategoryDatas");

        // console.log( Cookies.get('CookieFootercategoryDatas'));
         if(Cookies.get('CookieFootercategoryDatas')){
            this.categoryDatas = JSON.parse(Cookies.get('CookieFootercategoryDatas'));
         }else{
            this.fetchFooterData(this.language);
         }

         if(Cookies.get('CookiePaymentimages')){
            this.PaymentImage = JSON.parse(Cookies.get('CookiePaymentimages'));
         }else{
            this.fetchFooterData(this.language);
         }

         if(Cookies.get('CookieFooterSocialmediaData')){
            this.SocialmediaData = JSON.parse(Cookies.get('CookieFooterSocialmediaData'));
         }else{
            this.fetchFooterData(this.language);
         }

         if(Cookies.get('CookieFooterSpecialFeature')){
            this.SpecialFeature = JSON.parse(Cookies.get('CookieFooterSpecialFeature'));
         }else{
            this.fetchFooterData(this.language);
         }

         if(Cookies.get('CookieFooterFrontData')){
            this.FrontData = JSON.parse(Cookies.get('CookieFooterFrontData'));
         }else{
            this.fetchFooterData(this.language);
         }

         if(Cookies.get('CookieFooterFooterwidgetsettings')){
            this.Footerwidgetsettings = JSON.parse(Cookies.get('CookieFooterFooterwidgetsettings'));
         }else{
            this.fetchFooterData(this.language);
         }

         if(Cookies.get('CookieFooterFootersettingsData')){
            this.FootersettingsData = JSON.parse(Cookies.get('CookieFooterFootersettingsData'));
         }else{
            this.fetchFooterData(this.language);
         }



            
    },

    watch: {
        FrontData(newValue) {
            // Update the selectedLocale when LoginUserLanguageCode changes
            this.phonenoData = newValue.find(item => item.field_name === 'phone_number');
            this.emailData = newValue.find(item => item.field_name === 'email');
            this.addressData = newValue.find(item => item.field_name === 'address');
            this.SupportcenterEmail = newValue.find(item => item.field_name === 'customer_support_email');
           
           //console.log('frontData',this.customer_id);
        },
        FootersettingsData(newValue){
            this.companyName = newValue.find(item => item.field_name === 'company_name');
            this.descriptionData = newValue.find(item => item.field_name === 'footer_description');
            this.IOSappLink = newValue.find(item => item.field_name === 'ios_app_link');
            this.AndroidappLink = newValue.find(item => item.field_name === 'android_app_link');
            // console.log('frontData',this.FootersettingsData);
        }
      
    },

   
};
</script>


<template>
  <div id="footer-section">
    <footer>
        <section class="bg-dark py-5 text-light footer-widget">
            <div class="container">
                <div class="row g-3 service-section">
                    <div class="">
                        <div class="col-12">
                            <div class="service-contain">
                                <div class="service-box" v-for="data in SpecialFeature" :key="data.id">
                                    <div class="service-image">
                                        <img :src="baseUrl + data.image" class="blur-up lazyload" alt="">
                                    </div>

                                    <div class="service-detail">
                                        <h5>{{data.content}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-3">
                    <div class="main-footer section-b-space section-t-space">
                        <div class="row g-md-4 g-3 mt-2"  v-if="Footerwidgetsettings">
                            <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12 footer-li-links"  v-for="footerdata in Footerwidgetsettings" :key="footerdata.id">
                                <div class="footer-title">
                                    <h4>{{footerdata.title}}</h4>
                                </div>
                                <div class="footer-contain">
                                    <ul v-if="footerdata.title == 'Help Center'">                    
                                        <li>
                                            <a class="text-content" @click="redirectpageUrl(footerdata.content1,footerdata.link1)">{{ footerdata.content1 }}</a>  
                                                                            
                                        </li>
                                        <li>
                                            <a class="text-content" @click="redirectpageUrl(footerdata.content2,footerdata.link2)">{{ footerdata.content2 }}</a>  
                                                                                    
                                        </li>
                                        <li>
                                            <a class="text-content" @click="redirectpageUrl(footerdata.content3,footerdata.link3)">{{ footerdata.content3 }}</a>  
                                                                                    
                                        </li>
                                        <li>
                                            <a class="text-content" @click="redirectpageUrl(footerdata.content4,footerdata.link4)">{{ footerdata.content3 }}</a>  
                                                                                    
                                        </li>
                                        <!-- <li>
                                            <a class="text-content" :href="`${footerdata.link4}${LoginCustomerId}`" v-if="LoginStatus == 'true'">{{ footerdata.content4 }}</a>  
                                            <a  class="text-content" href="/login" v-else>{{ footerdata.content4 }}</a>                                     
                                        </li> -->
                                    </ul>
                                    <ul v-else>
                                        <li>
                                            <a class="text-content"  @click="redirectpagewithoutLoginUrl(footerdata.content1,footerdata.link1,footerdata.slug1)" >{{ footerdata.content1 }}</a>                                    
                                        </li>
                                        <li>
                                            <a  class="text-content" @click="redirectpagewithoutLoginUrl(footerdata.content2,footerdata.link2,footerdata.slug2)" >{{ footerdata.content2 }}</a>                                    
                                        </li>
                                        <li>
                                            <a  class="text-content" @click="redirectpagewithoutLoginUrl(footerdata.content3,footerdata.link3,footerdata.slug3)" >{{ footerdata.content3 }}</a>                                    
                                        </li>
                                        <li>
                                            <a  class="text-content" @click="redirectpagewithoutLoginUrl(footerdata.content4,footerdata.link4,footerdata.slug4)" >{{ footerdata.content4 }}</a>                                    
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-sm-12">
                                <div class="footer-logo">
                                    <div class="theme-logo">
                                        <a href="/">
                                            <img v-if="FrontendImage" :src="baseUrl + FrontendImage.image" class="blur-up lazyload" alt="logo">
                                        </a>
                                    </div>

                                    <div class="footer-logo-contain">
                                        <p v-if = "descriptionData">{{descriptionData.data}}</p>

                                        <ul class="address">
                                            <li v-if = "addressData">
                                                <i class="bi bi-house"></i>
                                                <a href="javascript:void(0)">{{ addressData.data }}</a>
                                            </li>
                                            <li>
                                                <i data-feather="mail"></i>
                                                <a href="javascript:void(0)" v-if = "emailData">{{ emailData.data }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                                
                            <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12 footer-li-links">
                                <span v-if = "phonenoData && emailData != null">
                                    <div class="footer-title">
                                        <h4>Contact Us</h4>
                                    </div>
                                </span>

                                <div class="footer-contain">
                                    <ul>
                                        <span>
                                        <li>
                                            <div class="footer-number"  v-if = "phonenoData">
                                                <i data-feather="phone"></i>
                                                <div class="contact-number">
                                                    <h6 class="text-content">Phone No :</h6>
                                                    <h6>{{phonenoData.data}}</h6>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div class="footer-number"   v-if="SupportcenterEmail" >
                                                <i data-feather="mail"></i>
                                                <div class="contact-number">
                                                    <h6 class="text-content">Email Address :</h6>
                                                    <h6>{{ SupportcenterEmail.data }}</h6>
                                                </div>
                                            </div>
                                        </li>
                                        </span>
                                        <li class="social-app" v-if="IOSappLink || AndroidappLink">
                                            <h5 class="mt-3 mb-2 p-1 text-content">Download App :</h5>
                                            <ul>
                                                <li class="mt-2" v-if="AndroidappLink">
                                                    <a :href="AndroidappLink.link" target="_blank">
                                                        <img src="../assets/images/playstore.png" class="blur-up lazyload"
                                                            alt="">
                                                    </a>
                                                </li>
                                                <li class="mb-0" v-if="IOSappLink">
                                                    <a :href="IOSappLink.link" target="_blank">
                                                        <img src="../assets/images/appstore.png" class="blur-up lazyload"
                                                            alt="">
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-3 mt-1">
                    <div class="sub-footer section-small-space">
                        <div class="reserve" v-if = "companyName">
                            <h6 class="text-content"> © Copyright {{ currentYear}} @ {{companyName.data}}</h6>
                        </div>
                        <div class="social-link">
                            <h6 class="text-content">Stay connected :</h6>
                            
                            <ul>
                                <li v-for="socialData in SocialmediaData" :key="socialData.id" class="socialmedia-li" :class="socialData.field_name" :style="{ backgroundColor: socialData.background_color }">
                                    <a :href="socialData.link" target="_blank" v-html="socialData.icon" ></a>
                                </li>
                            </ul>

                        </div>

                        <div class="payment">
                            <img v-for="payment in PaymentImage" :key="payment.id" :src="baseUrl + payment.image" class="blur-up lazyload" alt="" style="margin: 2px;">
                        </div>

                        
                    </div>
                </div>
                
            </div>        
        </section>
    </footer>
  </div>
</template>

<style scoped>
footer .sub-footer .social-link ul li a i {
    color: var(--theme-3-text-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


footer .sub-footer .social-link ul li a {
    display: block;
    margin-top: 7px;
    text-align: center;
}

.fa-facebook-f:before {
    content: "\f39e";
    color: #ffff;
}
.fa-twitter:before {
    content: "\f099";
    color: #ffff;
}
.fa-instagram:before {
    content: "\f16d";
    color: #ffff;
}
.socialmedia-li i {
    color: #ffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.socialmedia-li {
    border: 1px solid;
    width: 35px;
    height: 35px;
    border-radius: 50%;
   
}

   .text-light {
        --bs-text-opacity: 1;
        color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
        background: #111723 !important;
    }
    footer .main-footer .footer-contact ul li .footer-number {
        gap: 3px !important;
    }

@media (max-width: 768px) {
    footer .main-footer .footer-logo .footer-logo-contain p{
        
        width:100%;
    }
    footer .main-footer .footer-logo .footer-logo-contain .address li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: block;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 100%;
        align-items: center;
        
    }
}

    .footer-li-links{
        text-align: center;
    }

    @media (max-width: 767px) {
        footer .main-footer {
            border: none;
            padding-left: 30px;
        }
        .footer-li-links{
            text-align: left;
        }

        .footer-title h4 {
           
            color: var(--theme-3-color);
        }
    }
    @media (max-width: 678px){
        footer .main-footer .footer-logo .theme-logo {
            
            margin-left: 0px !important;
           
        }
        .footer-li-links{
            width:100%;

        }
        footer .main-footer .social-app ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            gap: 53px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            text-align: center;
        }
        footer .main-footer .footer-logo .footer-logo-contain {
            margin: 0px;
            padding-left: 0px !important;
        }
        footer .main-footer .footer-logo .footer-logo-contain .address li a {
            margin: 4px;
            color: #ffff;
            line-height: 18px;
            font-size: calc(14px +(16 - 14)*((100vw - 320px) /(1920 - 320)));
        }
        .contact-number, .text-content {
            display: flex;
            gap: 7px;
            flex-wrap: wrap;
        }
    }

    .service-contain .service-box .service-detail h5 {
        font-weight: 500;
        color: #ffff;
    }
    .text-content {
        color: #ffff;
    }
    footer .main-footer .footer-logo .footer-logo-contain p {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: #ffff;
        line-height: 27px;
        margin-bottom: 0;
        width: 88%;
    }

    footer .main-footer .footer-logo .footer-logo-contain .address li a {
        margin: 0 0 0 10px;
        color: #ffff;
        line-height: 18px;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    @media (max-width: 991px) {
        footer .sub-footer .social-link {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-top: 16px;
        }
    }

    footer .main-footer .footer-logo .theme-logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-left: 48px;
    }

    footer .main-footer .footer-logo .footer-logo-contain {
        margin: 0px;
        padding-left: 45px;
    }
</style>