<template>
    <button type="button" class="btn p-0 position-relative header-wishlist">
        <i data-feather="shopping-cart"></i>
        <span class="position-absolute top-0 start-100 translate-middle badge">{{ count }}
            <span class="visually-hidden">card items</span>
        </span>
    </button>
</template>

<script>

export default {
    components: {
        
    },
    props: {
        count: {
            type: Number,
            required: true
        }
    },
    computed: {

    },
    data() {
        return {
            
        };
    },
   

    setup() {

        return {


        };
    },
    methods: {
    

    },

    mounted() {
        
    }

}
</script>

<style scoped>
 .feather {
        color: var(--theme-3-text-color) !important;
    }
    .badge {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--theme-3-color) !important;
    font-size: 12px;
    padding: 0;
    border-radius: 2px;
}
</style>